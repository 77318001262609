import React, { Suspense } from 'react';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Notifications } from '@mantine/notifications';

import { AuthProvider, useAuth } from 'hooks/useAuth';
import { routeTree } from './routeTree.gen';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-react-table/styles.css';

// Create a new router instance
const router = createRouter({ routeTree, context: { authentication: undefined! } });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Only install devtools if not building prod.
const TanStackRouterDevtools = import.meta.env.DEV
  ? React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    )
  : () => null;

function InnerApp() {
  const authentication = useAuth();
  return (
    <>
      <RouterProvider router={router} context={{ authentication }} />
      <Suspense>
        <TanStackRouterDevtools router={router} />
      </Suspense>
    </>
  );
}

export default function App() {
  return (
    <MantineProvider forceColorScheme="light">
      <Notifications />
      <ModalsProvider>
        <AuthProvider>
          <InnerApp />
        </AuthProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}
