import { detailedDiff } from 'deep-object-diff';

import dayjs from 'dayjs';
import {
  CalendarEventIndex,
  CalendarEventIndexRaw,
  CalendarMatchupFull,
  CalendarMatchupRaw,
  FullCalendarEventIndexRaw,
  FullScheduleMetricsEntry,
  FullScheduleMetricsEntryRaw,
  isScheduleIndexArg,
  SCHEDULE_INDEX_MAP,
  ScheduleIndexArg,
  TeamMetricsEntry,
  TeamType,
} from './scheduleConsts';

export type DetailedScheduleDiff = {
  added: SimplifiedSchedule;
  deleted: SimplifiedSchedule;
  updated: SimplifiedSchedule;
};

export const getScheduleDisplayName = (id: ScheduleIndexArg) => SCHEDULE_INDEX_MAP[id] || 'Unknown';

export function parseMatchup({
  title,
  blackouts: rawBlackouts,
}: {
  title: string;
  blackouts?: string[];
}) {
  const blackouts = Array.from(rawBlackouts ?? []);
  const [away, home] = title.split('@', 2).map((team) => team.trim() as TeamType);
  const homeBlackout = (blackouts && blackouts.includes(home)) ?? false;
  const awayBlackout = (blackouts && blackouts.includes(away)) ?? false;

  return {
    home,
    away,
    homeBlackout,
    awayBlackout,
    displayValue: `${away}${awayBlackout ? '*' : ''} @ ${home}${homeBlackout ? '*' : ''}`,
  };
}

export const getScheduleJson: (
  id: string,
  team: TeamType
) => Promise<CalendarEventIndexRaw> = async (id: string, team: TeamType) => {
  try {
    const { default: data }: { default: CalendarEventIndexRaw } = await import(
      `../data/schedules/${id}/json/${team}_schedule.json`
    );
    return data;
  } catch (e) {
    // No schedule found for this team.
    return {};
  }
};

export const getFullScheduleJson: (id: string) => Promise<FullCalendarEventIndexRaw> = async (
  id: string
) => {
  const { default: data }: { default: FullCalendarEventIndexRaw } = await import(
    `../data/schedules/${id}/json/full_schedule.json`
  );
  return data;
};

export const getFullSchedule: (id: string) => Promise<CalendarMatchupFull[]> = async (
  id: string
) => {
  const raw = await getFullScheduleJson(id);
  const entries: CalendarMatchupFull[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchups] of Object.entries(raw)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const matchup of matchups) {
      entries.push({
        date,
        ...matchup,
      });
    }
  }
  return entries;
};

export const getTeamScheduleMetrics: (
  id: string,
  teamName: TeamType
) => Promise<TeamMetricsEntry> = async (id: string, teamName: TeamType) => {
  try {
    const { default: data }: { default: TeamMetricsEntry } = await import(
      `../data/schedules/${id}/json/${teamName}_schedule_metrics.json`
    );
    return data;
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      viewership_total: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stand_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      border_crossings_count: 0,
    };
  }
};

export const getFullScheduleMetrics: (id: string) => Promise<FullScheduleMetricsEntry> = async (
  id: string
) => {
  try {
    const { default: data }: { default: FullScheduleMetricsEntryRaw } = await import(
      `../data/schedules/${id}/json/full_schedule_metrics.json`
    );
    return {
      id,
      // @ts-ignore
      name: SCHEDULE_INDEX_MAP[id] || 'Unknown',
      ...data,
    };
  } catch (e) {
    // No schedule metrics found for this team.
    return {
      id: '-1',
      name: 'N/A',
      viewership_total: 0,
      travel_distance_in_miles_average: 0,
      travel_distance_in_miles_total: 0,
      longest_home_stands_length: 0,
      longest_road_trip_length: 0,
      back_to_back_match_count: 0,
      three_matches_in_five_days_count: 0,
      us_team_border_crossings_border_crossings_count: 0,
      canadian_team_border_crossings_border_crossings_count: 0,
    };
  }
};

export const getImportedIframeUrl: (targetUrl: string) => Promise<string> = async (
  targetUrl: string
) => {
  const resUrl = new URL(`../data/${targetUrl}`, import.meta.url).href;
  return resUrl;
};

export const processCalendarIndexRaw: (
  rawIndex: CalendarEventIndexRaw,
  selectedTeam: TeamType
) => CalendarEventIndex = (rawIndex: CalendarEventIndexRaw, selectedTeam: TeamType) => {
  const index: CalendarEventIndex = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, matchup] of Object.entries(rawIndex)) {
    if (matchup.home === selectedTeam) {
      index.push({
        title: `${matchup.away}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'home-event'],
        textColor: 'black',
      });
    } else {
      index.push({
        title: `@${matchup.home}`,
        date,
        eastern_time: matchup.eastern_time,
        className: ['event', 'away-event'],
        textColor: 'black',
      });
    }
  }
  return index;
};

export type SimplifiedSchedule = {
  [date: string]: {
    [matchup: string]: {
      time: string;
      network?: string;
      viewership?: string;
    };
  };
};

export class ScheduleDiffMap {
  private _initialized = false;
  private _schedules: { [K in ScheduleIndexArg]?: FullCalendarEventIndexRaw } = {};

  readonly scheduleNames: [string, string, ...string[]];

  static matchupHasChanges(date: string, matchup: string, ...diffs: DetailedScheduleDiff[]) {
    let hasChanges = false;

    for (const { added, deleted, updated } of diffs) {
      const addition = added[date]?.[matchup];
      const deletion = deleted[date]?.[matchup];
      const mutation = updated[date]?.[matchup];

      if (addition || deletion || mutation) {
        hasChanges = true;
        break;
      }
    }

    return hasChanges;
  }

  static isValidScheduleDiffIdList(
    ids: string[]
  ): ids is [ScheduleIndexArg, ScheduleIndexArg, ...ScheduleIndexArg[]] {
    if (ids.length < 2) {
      return false;
    }

    return ids.every(isScheduleIndexArg);
  }

  static async init<T extends string[]>(ids: T) {
    const filteredIds = ids.filter((id) => Number(id) > 11);

    if (!ScheduleDiffMap.isValidScheduleDiffIdList(filteredIds)) {
      return null;
    }

    const diffMap = new ScheduleDiffMap(filteredIds);

    return diffMap.fetchSchedules();
  }

  private constructor(readonly ids: [ScheduleIndexArg, ScheduleIndexArg, ...ScheduleIndexArg[]]) {
    this.scheduleNames = this.ids.map(getScheduleDisplayName) as [string, string, ...string[]];
  }

  async fetchSchedules() {
    const fetched = await Promise.all(
      this.ids.map(async (id) => {
        const schedule = await getFullScheduleJson(id);
        return [id, schedule] as const;
      })
    );

    for (const [id, schedule] of fetched) {
      this._schedules[id] = schedule;
    }

    this._initialized = true;
    return this;
  }

  private _getMatchupKey(matchup: CalendarMatchupRaw) {
    return `${matchup.away} @ ${matchup.home}`;
  }

  getMatchupBlackouts(id: ScheduleIndexArg, date: string, matchup: string) {
    if (!this._initialized) {
      throw new Error('Schedules not fetched yet');
    }

    const schedule = this._schedules[id];

    if (!schedule) {
      throw new Error('Schedule not found');
    }

    const game = schedule[date]?.find((m) => this._getMatchupKey(m) === matchup);

    if (!game) {
      throw new Error('Matchup not found');
    }

    return game.blackouts ?? [];
  }

  getIdsExcluding(id: ScheduleIndexArg) {
    return this.ids.filter((i) => i !== id);
  }

  private _getScheduleDateRange(id: ScheduleIndexArg) {
    if (!this._initialized) {
      throw new Error('Schedules not fetched yet');
    }

    const schedule = this._schedules[id];

    if (!schedule) {
      throw new Error('Schedule not found');
    }

    const dates = Object.keys(schedule)
      .map((d) => dayjs(d))
      .sort((a, b) => a.diff(b));

    return {
      start: dates[0],
      end: dates[dates.length - 1],
    };
  }

  getSchedulesDateRange() {
    return this.ids
      .map((id) => this._getScheduleDateRange(id))
      .reduce((range, other) => {
        if (other.start.isBefore(range.start)) {
          // eslint-disable-next-line no-param-reassign
          range.start = other.start;
        }

        if (other.end.isAfter(range.end)) {
          // eslint-disable-next-line no-param-reassign
          range.end = other.end;
        }

        return range;
      });
  }

  getSchedulesDates() {
    const { start, end } = this.getSchedulesDateRange();
    const afterEnd = end.add(1, 'day');
    const dates: string[] = [];

    let current = start;

    while (current.isBefore(afterEnd)) {
      dates.push(current.format('YYYY-MM-DD'));
      current = current.add(1, 'day');
    }

    return dates;
  }

  private _getSimplifiedSchedule(id: ScheduleIndexArg, focus: Focus | [Focus, ...Focus[]]) {
    if (!this._initialized) {
      throw new Error('Schedules not fetched yet');
    }

    const simplified: SimplifiedSchedule = {};
    const schedule = this._schedules[id];

    if (!schedule) {
      throw new Error('Schedule not found');
    }

    const include = new Set(Array.isArray(focus) ? focus : [focus]);

    for (const [date, games] of Object.entries(schedule)) {
      for (const matchup of games) {
        simplified[date] ??= {};
        simplified[date][this._getMatchupKey(matchup)] ??= {
          time: matchup.eastern_time,
        };

        if (include.has('network')) {
          simplified[date][this._getMatchupKey(matchup)].network = (matchup.network ?? '')
            .replace('ESPN_PLUS', 'ESPN+')
            .replace('ESPN_TWO', 'ESPN2');
        }

        if (include.has('viewership')) {
          simplified[date][this._getMatchupKey(matchup)].viewership =
            matchup.viewership?.toString() ?? '';
        }
      }
    }

    return simplified;
  }

  getSchedule(id: ScheduleIndexArg, focus: Focus | [Focus, ...Focus[]]) {
    if (!this._initialized) {
      throw new Error('Schedules not fetched yet');
    }

    return this._getSimplifiedSchedule(id, focus);
  }

  getChangeset(
    id: ScheduleIndexArg,
    otherId: ScheduleIndexArg,
    focus: Focus | [Focus, ...Focus[]]
  ) {
    if (!this._initialized) {
      throw new Error('Schedules not fetched yet');
    }

    return detailedDiff(
      this._getSimplifiedSchedule(id, focus),
      this._getSimplifiedSchedule(otherId, focus)
    ) as DetailedScheduleDiff;
  }
}

type Focus = 'network' | 'viewership';
