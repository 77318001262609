/* eslint-disable no-plusplus */
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Image,
  MultiSelect,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { createFileRoute, getRouteApi, useNavigate } from '@tanstack/react-router';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import { useAppStore } from 'stores/appStore';

import CompareTable from 'components/CompareTable';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import {
  FullScheduleMetricsEntry,
  SCHEDULE_INDEX_MAP,
  ScheduleIndexArg,
} from 'utils/scheduleConsts';
import {
  DetailedScheduleDiff,
  getFullScheduleMetrics,
  getScheduleDisplayName,
  parseMatchup,
  ScheduleDiffMap,
  SimplifiedSchedule,
} from 'utils/scheduleUtils';

import clsx from 'clsx';
import classes from './compare.module.css';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const downloadDiffTable = () => {
  const tableElement = document.getElementById('diffTable');
  if (!tableElement) {
    console.error('Diff table not found');
    return;
  }
  const tableCopy = tableElement.cloneNode(true) as HTMLTableElement;

  // Remove unnecessary elements and replace placeholders
  Array.from(tableCopy.getElementsByClassName('print-remove')).forEach((el) => el.remove());
  Array.from(tableCopy.getElementsByClassName('print-placeholder')).forEach((el) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    el.innerText = el.dataset.placeholder;
  });

  // Construct the primary header.
  const primaryHeaderMain = tableCopy.getElementsByClassName(
    'print-primary-header-main'
    // @ts-ignore
  )[0].innerText;
  const primaryHeader = ['', primaryHeaderMain, '', '', ''];

  const primaryHeaderAddl = Array.from(
    tableCopy.getElementsByClassName('print-primary-header-addl')
    // @ts-ignore
  ).map((el) => el.innerText);
  for (const header of primaryHeaderAddl) {
    primaryHeader.push(header);
    primaryHeader.push('');
    primaryHeader.push('');
  }

  // Construct the secondary header.
  const secondaryHeader = Array.from(
    tableCopy.getElementsByClassName('print-secondary-header')
    // @ts-ignore
  ).map((el) => el.innerText);
  secondaryHeader.unshift('Date');

  let csvContent = '';

  // eslint-disable-next-line prefer-template
  csvContent += primaryHeader.join(',') + '\n';
  // eslint-disable-next-line prefer-template
  csvContent += secondaryHeader.join(',') + '\n';

  const tableBody = tableCopy.getElementsByTagName('tbody')![0];

  // Iterate through rows of the table body
  // @ts-ignore
  for (let i = 0; i < tableBody.rows.length; i++) {
    // @ts-ignore
    const row = tableBody.rows[i];
    const rowData = [];

    // Iterate through each cell in the row
    for (let j = 0; j < row.cells.length; j++) {
      rowData.push(`"${row.cells[j].innerText}"`); // Get the innerText of each cell
    }

    // eslint-disable-next-line prefer-template
    csvContent += rowData.join(',') + '\n'; // Join the cell values with commas and add newline
  }

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // Create a temporary link to download the CSV
  const a = document.createElement('a');
  a.href = url;
  a.download = 'table_data.csv';
  document.body.appendChild(a);
  a.click();

  // Clean up the link after download
  document.body.removeChild(a);
};

function sameBlackouts(blackouts1: string[] | undefined, blackouts2: string[] | undefined) {
  return (
    Array.from(blackouts1 ?? [])
      .sort()
      .join() ===
    Array.from(blackouts2 ?? [])
      .sort()
      .join()
  );
}

function formatNum(num?: number | string) {
  if (num === undefined || num === '') {
    return '';
  }

  return Number(num).toLocaleString();
}

function Matchup({
  matchup,
  blackouts,
  didChange,
}: {
  matchup: string;
  blackouts?: string[];
  didChange?: boolean;
}) {
  const { home, away, displayValue } = useMemo(
    () => parseMatchup({ title: matchup, blackouts }),
    [matchup, blackouts]
  );

  return (
    <Table.Td
      className={clsx(
        didChange === false && classes.noChangeCell,
        didChange === true && classes.mutationCell
      )}
    >
      <Group gap="5px" align="center">
        <Box h={20}>
          <Image
            src={new URL(`/src/assets/team_logos/${away}_logo.png`, import.meta.url).href}
            height={20}
            fit="cover"
            alt={`${away} logo`}
          />
        </Box>
        <Text size="sm" component="span">
          {displayValue}
        </Text>
        <Box h={20}>
          <Image
            src={new URL(`/src/assets/team_logos/${home}_logo.png`, import.meta.url).href}
            height={20}
            fit="cover"
            alt={`${home} logo`}
          />
        </Box>
      </Group>
    </Table.Td>
  );
}

function FromChangeset({
  date,
  matchup,
  changesets,
  changesetIndex,
  originalSchedule,
  originalScheduleId,
  otherScheduleId,
}: {
  date: string;
  matchup: string;
  changesets: DetailedScheduleDiff[];
  changesetIndex: number;
  originalSchedule: SimplifiedSchedule;
  originalScheduleId: ScheduleIndexArg;
  otherScheduleId: ScheduleIndexArg;
}) {
  const { added, deleted, updated } = changesets[changesetIndex] ?? {
    added: {},
    deleted: {},
    updated: {},
  };

  const { scheduleDiffs } = useAppStore();

  if (!scheduleDiffs) {
    return null;
  }

  const original = originalSchedule[date]?.[matchup] ?? {};
  const addition = added[date]?.[matchup];
  const deletion = deleted[date]?.[matchup];
  const mutation = updated[date]?.[matchup];

  const otherBlackouts = scheduleDiffs.getMatchupBlackouts(otherScheduleId, date, matchup);
  const originalBlackouts = scheduleDiffs.getMatchupBlackouts(originalScheduleId, date, matchup);

  const cells: ReactNode[] = [
    <Matchup
      key="matchup"
      matchup={matchup}
      blackouts={otherBlackouts}
      didChange={!sameBlackouts(originalBlackouts, otherBlackouts)}
    />,
  ];

  if (addition || deletion || mutation) {
    if (original.network) {
      if (deletion && 'network' in deletion) {
        cells.push(<Table.Td key="network" className={clsx(classes.deletionCell)} />);
      } else if ('network' in mutation) {
        cells.push(
          <Table.Td
            key="network"
            className={clsx(!mutation.network ? classes.deletionCell : classes.mutationCell)}
          >
            <div>{mutation.network}</div>
          </Table.Td>
        );
      } else {
        cells.push(
          <Table.Td key="network" className={clsx(classes.noChangeCell)}>
            <div>{original.network}</div>
          </Table.Td>
        );
      }
    } else if (addition?.network || mutation?.network) {
      cells.push(
        <Table.Td key="network" className={clsx(classes.additionCell)}>
          <div>{addition?.network || mutation?.network}</div>
        </Table.Td>
      );
    } else {
      cells.push(<Table.Td key="network" />);
    }

    if (original.viewership) {
      if (deletion && 'viewership' in deletion) {
        cells.push(<Table.Td key="viewership" className={clsx(classes.deletionCell)} />);
      } else if ('viewership' in mutation) {
        cells.push(
          <Table.Td
            key="viewership"
            className={clsx(
              Number(original.viewership || '0') > Number(mutation.viewership || '0')
                ? classes.deletionCell
                : classes.additionCell
            )}
          >
            <div>{formatNum(mutation.viewership)}</div>
          </Table.Td>
        );
      } else {
        cells.push(
          <Table.Td key="viewership" className={clsx(classes.noChangeCell)}>
            <div>{formatNum(original.viewership)}</div>
          </Table.Td>
        );
      }
    } else if (addition?.viewership || mutation?.viewership) {
      cells.push(
        <Table.Td key="viewership" className={clsx(classes.additionCell)}>
          <div>{formatNum(addition?.viewership || mutation?.viewership)}</div>
        </Table.Td>
      );
    } else {
      cells.push(<Table.Td key="viewership" />);
    }

    return <>{cells}</>;
  }

  // No changes.
  return (
    <>
      <Matchup matchup={matchup} blackouts={originalBlackouts} didChange={false} />
      <Table.Td className={classes.noChangeCell}>
        <div>{original.network}</div>
      </Table.Td>
      <Table.Td className={classes.noChangeCell}>
        <div>{formatNum(original.viewership)}</div>
      </Table.Td>
    </>
  );
}

function ComparePage() {
  const navigate = useNavigate({ from: '/compare' });
  const routeSearch = getRouteApi('/_authenticated/compare').useSearch();
  const { comparedScheduleIdList, setComparedScheduleIdList, scheduleDiffs, setScheduleDiffs } =
    useAppStore();

  const theme = useMantineTheme();

  const onlyTwoSchedules = comparedScheduleIdList.length === 2;

  // Load in `compare` search param from URL.
  const compare = Array.from(routeSearch.compare || []);
  useEffect(() => {
    if (!compare.length) {
      const newCompare = Object.keys(SCHEDULE_INDEX_MAP).reverse().slice(0, 3);
      setComparedScheduleIdList(newCompare);
    }
    if (compare.join(',') !== comparedScheduleIdList.join(',')) {
      setComparedScheduleIdList(compare);
    }
  }, []);

  const [scheduleMetrics, setScheduleMetrics] = useState<FullScheduleMetricsEntry[]>([]);

  useEffect(() => {
    async function doWork() {
      const [changes, metrics] = await Promise.all([
        await ScheduleDiffMap.init(comparedScheduleIdList),
        Promise.all(comparedScheduleIdList.map(getFullScheduleMetrics)),
      ]);

      setScheduleDiffs(changes);
      setScheduleMetrics(metrics);

      if (changes) {
        await navigate({ search: (prev) => ({ ...prev, t: changes.ids[0] }) });
      } else {
        await navigate({ search: (prev) => ({ ...prev, t: undefined }) });
      }
    }

    doWork();
  }, [comparedScheduleIdList]);

  return (
    <Flex mih="calc(100dvh - 64px)" m="md" pos="relative">
      <Stack w="100%" gap="xs">
        <Title order={2}>Compare Schedules</Title>
        <Divider />
        <Stack>
          <MultiSelect
            label="Select schedules to compare"
            data={Object.entries(SCHEDULE_INDEX_MAP).map(([id, name]) => ({
              label: name,
              value: id,
            }))}
            value={comparedScheduleIdList}
            searchable
            onChange={(value) => {
              const sortedValue = value.sort((a, b) => Number(a) - Number(b));
              setComparedScheduleIdList(sortedValue);
              navigate({
                to: '/compare',
                search: { compare: sortedValue.length ? sortedValue : undefined },
              });
            }}
            w={800}
          />
          {comparedScheduleIdList.length ? (
            <CompareTable scheduleMetrics={scheduleMetrics} />
          ) : null}
        </Stack>
        {scheduleDiffs && (
          <>
            <Title order={2}>Schedule Differences</Title>
            <Flex gap={24}>
              <Flex align="center" gap={8}>
                <Box
                  h={16}
                  w={16}
                  bg={theme.colors.red[1]}
                  style={{ border: 'solid 1px', borderColor: theme.colors.red[3] }}
                />
                <Text size="sm">deleted or negative change</Text>
              </Flex>
              <Flex align="center" gap={8}>
                <Box
                  h={16}
                  w={16}
                  bg={theme.colors.green[1]}
                  style={{ border: 'solid 1px', borderColor: theme.colors.green[4] }}
                />
                <Text size="sm">added or positive change</Text>
              </Flex>
              <Flex align="center" gap={8}>
                <Box
                  h={16}
                  w={16}
                  bg={theme.colors.orange[1]}
                  style={{ border: 'solid 1px', borderColor: theme.colors.orange[3] }}
                />
                <Text size="sm">network or blackout change</Text>
              </Flex>
              <Flex align="center" gap={8} w="100%">
                <Flex
                  h={16}
                  w={16}
                  fz="xs"
                  align="center"
                  justify="center"
                  c={theme.colors.gray[5]}
                  style={{ border: 'solid 1px', borderColor: theme.colors.gray[3] }}
                >
                  A
                </Flex>
                <Text size="sm">no change</Text>
                <Button ml="auto" size="xs" onClick={downloadDiffTable}>
                  Export
                </Button>
              </Flex>
            </Flex>
            <Stack gap={8}>
              {!onlyTwoSchedules && (
                <Text size="xs" fs="italic" fw="lighter" fz="xs">
                  * When comparing more than two schedules, the first (base) schedule is compared to
                  each of the others. To change the base schedule, select a different tab.
                </Text>
              )}
            </Stack>
            <Tabs
              py="xs"
              variant="outline"
              classNames={{
                list: clsx(classes.comparePage, classes.tabsList),
                panel: clsx(classes.comparePage, classes.tabsPanel),
              }}
              value={routeSearch.t ?? scheduleDiffs.ids[0]}
              onChange={(value) => {
                navigate({ search: (prev) => ({ ...prev, t: value ?? undefined }) });
              }}
              w="100%"
              h="calc(100% - 75px)"
              keepMounted={false}
            >
              <Tabs.List display={onlyTwoSchedules ? 'none' : ''}>
                {scheduleDiffs.ids.map((id) => (
                  <Tabs.Tab key={id} value={id}>
                    {getScheduleDisplayName(id)}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              {scheduleDiffs.ids.map((id) => {
                const otherIds = scheduleDiffs.getIdsExcluding(id);
                const originalSchedule = scheduleDiffs.getSchedule(id, ['network', 'viewership']);

                const changesets = otherIds.map((otherId) =>
                  scheduleDiffs.getChangeset(id, otherId, ['network', 'viewership'])
                );

                const datesWithChanges = [
                  ...changesets.reduce((acc, { added, deleted, updated }) => {
                    for (const date of Object.keys(added)) {
                      acc.add(date);
                    }
                    for (const date of Object.keys(deleted)) {
                      acc.add(date);
                    }
                    for (const date of Object.keys(updated)) {
                      acc.add(date);
                    }

                    return acc;
                  }, new Set<string>()),
                ].sort((a, b) => dayjs(a).diff(dayjs(b)));

                return (
                  <Tabs.Panel key={id} value={id} mb="xl">
                    <Table
                      id="diffTable"
                      highlightOnHover
                      withTableBorder={false}
                      withRowBorders={false}
                      withColumnBorders={false}
                      stickyHeader
                      stickyHeaderOffset={onlyTwoSchedules ? 40 : 76}
                      classNames={{
                        table: clsx(classes.comparePage, classes.table),
                        thead: clsx(classes.comparePage, classes.tableHead),
                        tbody: clsx(classes.comparePage, classes.tableBody),
                        td: clsx(classes.comparePage, classes.tableCell),
                      }}
                    >
                      <Table.Thead>
                        <Table.Tr h={80}>
                          <Table.Th rowSpan={2} w={120} />
                          <Table.Th colSpan={4} className="print-primary-header-main">
                            {getScheduleDisplayName(id)}
                          </Table.Th>
                          {otherIds.map((otherId) => (
                            <Table.Th
                              key={otherId}
                              colSpan={3}
                              className="print-primary-header-addl"
                            >
                              {getScheduleDisplayName(otherId)}
                            </Table.Th>
                          ))}
                          <Table.Th className="print-remove" />
                        </Table.Tr>
                        <Table.Tr h={40}>
                          <Table.Th w={60} className="print-secondary-header">
                            Time
                          </Table.Th>
                          <Table.Th w={180} className="print-secondary-header">
                            Matchup
                          </Table.Th>
                          <Table.Th w={120} className="print-secondary-header">
                            Network
                          </Table.Th>
                          <Table.Th w={120} className="print-secondary-header">
                            Viewership
                          </Table.Th>
                          {otherIds.map((otherId) => (
                            <Fragment key={otherId}>
                              <Table.Th w={180} className="print-secondary-header">
                                Matchup
                              </Table.Th>
                              <Table.Th w={120} className="print-secondary-header">
                                Network
                              </Table.Th>
                              <Table.Th w={120} className="print-secondary-header">
                                Viewership
                              </Table.Th>
                            </Fragment>
                          ))}
                          <Table.Th className="print-remove" />
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {datesWithChanges.map((date) => {
                          const matches = originalSchedule[date] ?? {};

                          if (!Object.keys(matches).length) {
                            return null;
                          }

                          const matchRows = Object.entries(matches).map(([matchup, original]) => {
                            if (!ScheduleDiffMap.matchupHasChanges(date, matchup, ...changesets)) {
                              return null;
                            }

                            const originalBlackouts = scheduleDiffs.getMatchupBlackouts(
                              id,
                              date,
                              matchup
                            );

                            return (
                              <Table.Tr key={matchup}>
                                <Table.Td
                                  className="print-placeholder"
                                  data-placeholder={dayjs(date).format('ddd MMM DD')}
                                />
                                <Table.Td className={classes.timeCell}>{original.time}</Table.Td>
                                <Matchup matchup={matchup} blackouts={originalBlackouts} />
                                <Table.Td>{original.network}</Table.Td>
                                <Table.Td>{formatNum(original.viewership)}</Table.Td>
                                {otherIds.map((otherId, i) => (
                                  <FromChangeset
                                    key={otherId}
                                    date={date}
                                    matchup={matchup}
                                    changesets={changesets}
                                    changesetIndex={i}
                                    originalSchedule={originalSchedule}
                                    originalScheduleId={id}
                                    otherScheduleId={otherId}
                                  />
                                ))}
                                <Table.Td className="print-remove" />
                              </Table.Tr>
                            );
                          });

                          if (matchRows.filter(Boolean).length === 0) {
                            return null;
                          }

                          return (
                            <Fragment key={date}>
                              <Table.Tr className="print-remove">
                                <Table.Td
                                  colSpan={6 + 3 * otherIds.length}
                                  className={classes.dateCell}
                                >
                                  {dayjs(date).format('ddd, MMM DD')}
                                </Table.Td>
                              </Table.Tr>
                              {matchRows}
                            </Fragment>
                          );
                        })}
                      </Table.Tbody>
                    </Table>
                  </Tabs.Panel>
                );
              })}
            </Tabs>
          </>
        )}
      </Stack>
    </Flex>
  );
}

const schedulesSearchSchema = z.object({
  // tab
  t: z.string().optional(),
  // compare
  compare: z.string().array().optional(),
});

export const Route = createFileRoute('/_authenticated/compare')({
  component: ComparePage,
  validateSearch: (search) => schedulesSearchSchema.parse(search),
});
