import { Box, Flex, Group, Image, Select, Stack, Text, Title } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';

import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import numeral from 'numeral';

import { useAppStore } from 'stores/appStore';

import { getRouteApi, useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import {
  CalendarEvent,
  CalendarEventIndex,
  TEAM_KEYS,
  TEAM_NAME_MAP,
  TeamMetricsEntry,
  TeamType,
} from 'utils/scheduleConsts';
import {
  getScheduleJson,
  getTeamScheduleMetrics,
  processCalendarIndexRaw,
} from 'utils/scheduleUtils';

import classes from './TeamSummary.module.css';

function TeamMetricsGrid({ metrics }: { metrics: TeamMetricsEntry }) {
  return (
    <Box className={classes.metricsGridRoot}>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={105}>
            Viewership
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {numeral(metrics.viewership_total).format('0a')}
          </Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={105}>
            Travel Distance
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {numeral(metrics.travel_distance_in_miles_total).format('0a')}
          </Box>
        </Box>
      </Box>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={150}>
            Longest Home Stand
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.longest_home_stand_length}</Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={150}>
            Longest Road Trip
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.longest_road_trip_length}</Box>
        </Box>
      </Box>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={110}>
            B2B Games
          </Box>
          <Box className={classes.metricsGridItemValue}>{metrics.back_to_back_match_count}</Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={110}>
            3 Games/5 Days
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {metrics.three_matches_in_five_days_count}
          </Box>
        </Box>
      </Box>
      <Box className={classes.metricsGridColumn}>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={120}>
            Border Crossings
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {metrics.border_crossings_count || 'n/a'}
          </Box>
        </Box>
        <Box className={classes.metricsGridItem}>
          <Box className={classes.metricsGridItemLabel} w={120}>
            5 Games/7 Days
          </Box>
          <Box className={classes.metricsGridItemValue}>
            {metrics.five_matches_in_seven_days_count || 'n/a'}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function TeamSummary() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);

  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedTeam, setSelectedTeam] = useState<TeamType>(
    // @ts-ignore
    routeSearch.team ? routeSearch.team : 'ANA'
  );

  const [events, setEvents] = useState<CalendarEventIndex>([]);

  const [teamMetricsEntry, setTeamMetricsEntry] = useState<TeamMetricsEntry>();

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({ s: prev.s, t: 'team', team: selectedTeam, compare: prev.compare }),
    });

    async function fetchScheduleJson() {
      const scheduleJson = await getScheduleJson(currentScheduleId, selectedTeam);
      const newEvents = processCalendarIndexRaw(scheduleJson, selectedTeam);

      const teamMetricJson = await getTeamScheduleMetrics(currentScheduleId, selectedTeam);
      setEvents(newEvents);
      setTeamMetricsEntry(teamMetricJson);
    }
    fetchScheduleJson();
  }, [selectedTeam, currentScheduleId]);

  const columns = useMemo<MRT_ColumnDef<CalendarEvent>[]>(
    () => [
      {
        id: 'opponent',
        accessorFn: (row) => {
          const isHome = row.title.startsWith('@');
          const opp = isHome ? row.title.substring(1) : row.title;
          return (
            <Group gap="5px" align="center">
              {isHome ? '@' : 'vs'}
              <Box h={20}>
                <Image
                  src={new URL(`/src/assets/team_logos/${opp}_logo.png`, import.meta.url).href}
                  height={20}
                  fit="cover"
                  alt={`${opp} logo`}
                />
              </Box>
              {opp}
            </Group>
          );
        },
        sortingFn: (rowA, rowB) => {
          const a = rowA.original.title.startsWith('@')
            ? rowA.original.title.substring(1)
            : rowA.original.title;
          const b = rowB.original.title.startsWith('@')
            ? rowB.original.title.substring(1)
            : rowB.original.title;
          return a.localeCompare(b);
        },
        header: 'Opponent',
        filterFn: 'opponentFilter',
      },
      {
        id: 'date',
        accessorFn: (row) => dayjs(row.date).format('ddd, MMM DD'),
        header: 'Date',
        sortingFn: (rowA, rowB) => dayjs(rowA.original.date).diff(dayjs(rowB.original.date)),
      },
      {
        id: 'eastern_time',
        accessorFn: (row) => dayjs(row.eastern_time, 'HH:mm').format('h:mm A'),
        header: 'Time (ET)',
      },
    ],
    []
  );
  const constraintsTable = useMantineReactTable({
    data: events,
    columns,
    layoutMode: 'grid-no-grow',
    filterFns: {
      opponentFilter: (row, columnId, searchTerm) => {
        if (columnId !== 'opponent') {
          return true;
        }
        const terms = searchTerm.split(' ');
        // @ts-ignore
        return terms.every((term) => {
          if ((term === 'v' || term === 'vs') && !row.original.title.startsWith('@')) {
            return true;
          }
          if (term === '@') {
            return row.original.title.startsWith('@');
          }
          return row.original.title.toLowerCase().includes(term.toLowerCase());
        });
      },
    },
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    initialState: {
      // @ts-ignore
      density: '6px',
      sorting: [{ id: 'date', desc: false }],
    },
    mantineTableContainerProps: { style: { maxHeight: 'calc(100% - 80px)' } },
    paginationDisplayMode: 'pages',
    mantineTableProps: {
      striped: true,
    },
    enableStickyHeader: true,
    enableGlobalFilter: false,
    enablePagination: false,
    enableRowNumbers: true,
    renderBottomToolbar: ({ table }) => (
      <Flex
        justify="flex-end"
        bg="#F8F9FA"
        pr="lg"
        h="24px"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          fontSize: '14px',
        }}
        align="center"
      >
        {table.getRowModel().rows.length} results
      </Flex>
    ),
  });

  return (
    <Stack gap="xs" align="stretch" h="100%">
      <Group gap="xs">
        <Group align="center">
          <Text fw={500}>Team</Text>
          <Select
            value={selectedTeam}
            onChange={(value) => setSelectedTeam(value as TeamType)}
            allowDeselect={false}
            data={TEAM_KEYS.slice().sort()}
            searchable
            w="100px"
          />
          <Group gap="xs">
            <Box h={32}>
              <Image
                src={
                  new URL(`/src/assets/team_logos/${selectedTeam}_logo.png`, import.meta.url).href
                }
                height={32}
                width="auto"
                fit="cover"
                alt={`${selectedTeam} logo`}
              />
            </Box>
            <Title order={4} mr="md">
              {TEAM_NAME_MAP[selectedTeam as keyof typeof TEAM_NAME_MAP]}
            </Title>
          </Group>
        </Group>
        {teamMetricsEntry && <TeamMetricsGrid metrics={teamMetricsEntry} />}
      </Group>
      <MantineReactTable table={constraintsTable} />
    </Stack>
  );
}
