import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function initMonitoring() {
  if (import.meta.env.VITE_DATADOG_APP_ID === undefined) {
    return;
  }
  const application = import.meta.env.VITE_DATADOG_APPLICATION_NAME;
  const environment = import.meta.env.VITE_DATADOG_APPLICATION_ENV;
  const app_version = import.meta.env.VITE_DATADOG_APPLICATION_VERSION;
  const datadog_application_id = import.meta.env.VITE_DATADOG_APP_ID;
  const datadog_client_token = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
  datadogRum.init({
    applicationId: datadog_application_id,
    clientToken: datadog_client_token,
    service: application,
    env: environment,
    version: app_version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    telemetrySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    useSecureSessionCookie: true,
    proxy: (options) =>
      `${import.meta.env.VITE_BACKEND_URL}/dd-intake${options.path}/ra?${options.parameters}`,
  });

  datadogLogs.init({
    clientToken: datadog_client_token,
    service: application,
    env: environment,
    version: app_version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    useSecureSessionCookie: true,
    proxy: (options) =>
      `${import.meta.env.VITE_BACKEND_URL}/dd-intake${options.path}/ra?${options.parameters}`,
  });
}
