import { BuildListEntry, UserInfoResponse } from 'utils/scheduleConsts';

import { getBuildsListLocal } from 'api/localFoldersApi';
import ubiquiaAxios from './ubiquiaAxios';

export const getApiUrl = () => import.meta.env.VITE_BACKEND_URL || 'local';

export const getBuildsList = async (): Promise<BuildListEntry[]> => getBuildsListLocal();

export const getUserInfo = async (): Promise<UserInfoResponse> => {
  const getReq = await ubiquiaAxios.get('/api/v1/users/info/');
  return getReq.data;
};
