export const SCHEDULE_INDEX_MAP = {
  1: 'Season 22/23 (official)',
  2: 'Season 23/24 (official)',
  3: 'Season 24/25 (official)',
  4: 'Season 24/25 (alt. 1)',
  5: 'Season 24/25 (alt. 2)',
  6: 'Season 24/25 (alt. 3)',
  7: 'Season 24/25 (alt. 4)',
  8: 'Season 24/25 (alt. 5)',
  9: 'Season 24/25 (alt. 6)',
  10: 'Season 24/25 (alt. 7)',
  11: 'Season 24/25 (alt. 8)',
  12: 'Season 24/25 (official tv broadcasts)',
  13: 'Season 24/25 (official tv broadcasts - blackout optimized)',
  14: 'Season 24/25 (optimized tv broadcasts alt. 1)',
  15: 'Season 24/25 (optimized tv broadcasts alt. 2)',
  16: 'Season 24/25 (optimized tv broadcasts alt. 3)',
  17: 'Season 24/25 (optimized tv broadcasts alt. 4)',
} as const;

export type ScheduleIndexes = keyof typeof SCHEDULE_INDEX_MAP;

export type ScheduleIndexArg = `${ScheduleIndexes}`;

export function isScheduleIndexArg(arg: string): arg is ScheduleIndexArg {
  if (!(arg in SCHEDULE_INDEX_MAP)) {
    return false;
  }

  return true;
}

export const SCHEDULE_TO_YEAR_MAP = {
  1: 2022,
  2: 2023,
} as const;

export type ScheduleListEntry = {
  id: string;
  name: string;
  created_at: string;
  job_id: string;
  is_valid: boolean;
};

export type JobListEntry = {
  id: string;
  status: string;
  created_at: string;
};

export type BuildListEntry = {
  id: string;
  user: string;
  jobs: JobListEntry[];
  results: ScheduleListEntry[];
  created_at: string;
  name: string;
};

export const TEAM_KEYS = [
  'ANA',
  'ARI',
  'BOS',
  'BUF',
  'CAR',
  'CBJ',
  'CGY',
  'CHI',
  'COL',
  'DAL',
  'DET',
  'EDM',
  'FLA',
  'LAK',
  'MIN',
  'MTL',
  'NJD',
  'NSH',
  'NYI',
  'NYR',
  'OTT',
  'PHI',
  'PIT',
  'SEA',
  'SJS',
  'STL',
  'TBL',
  'TOR',
  'UTA',
  'VAN',
  'VGK',
  'WPG',
  'WSH',
] as const;

export const TEAM_NAME_MAP = {
  ANA: 'Anaheim Ducks',
  ARI: 'Arizona Coyotes',
  BOS: 'Boston Bruins',
  BUF: 'Buffalo Sabres',
  CAR: 'Carolina Hurricanes',
  CBJ: 'Columbus Blue Jackets',
  CGY: 'Calgary Flames',
  CHI: 'Chicago Blackhawks',
  COL: 'Colorado Avalanche',
  DAL: 'Dallas Stars',
  DET: 'Detroit Red Wings',
  EDM: 'Edmonton Oilers',
  FLA: 'Florida Panthers',
  LAK: 'Los Angeles Kings',
  MIN: 'Minnesota Wild',
  MTL: 'Montreal Canadiens',
  NJD: 'New Jersey Devils',
  NSH: 'Nashville Predators',
  NYI: 'New York Islanders',
  NYR: 'New York Rangers',
  OTT: 'Ottawa Senators',
  PHI: 'Philadelphia Flyers',
  PIT: 'Pittsburgh Penguins',
  SEA: 'Seattle Kraken',
  SJS: 'San Jose Sharks',
  STL: 'St. Louis Blues',
  TBL: 'Tampa Bay Lightning',
  TOR: 'Toronto Maple Leafs',
  UTA: 'Utah Hockey Club',
  VAN: 'Vancouver Canucks',
  VGK: 'Vegas Golden Knights',
  WPG: 'Winnipeg Jets',
  WSH: 'Washington Capitals',
} as const;

export type TeamType = (typeof TEAM_KEYS)[number];

export type CalendarMatchupRaw = {
  home: TeamType;
  away: TeamType;
  eastern_time: string;
  viewership?: number;
  network?: string;
  blackouts?: TeamType[];
};

export type CalendarEventIndexRaw = {
  [key: string]: CalendarMatchupRaw;
};

export type FullCalendarEventIndexRaw = {
  [key: string]: CalendarMatchupRaw[];
};

export type CalendarMatchupFull = CalendarMatchupRaw & {
  date: string;
};

export type CalendarEvent = {
  title: string;
  date: string;
  eastern_time: string;
  network?: string;
  viewership?: number;
  className?: string[];
  textColor?: string;
  blackouts?: TeamType[];
};

export type CalendarEventIndex = CalendarEvent[];

export type TeamMetricsEntry = {
  viewership_total: number;
  travel_distance_in_miles_total: number;
  longest_home_stand_length: number;
  longest_road_trip_length: number;
  back_to_back_match_count: number;
  three_matches_in_five_days_count: number;
  five_matches_in_seven_days_count?: number;
  border_crossings_count?: number;
};

export type FullScheduleMetricsEntryRaw = {
  viewership_total: number;
  travel_distance_in_miles_average: number;
  travel_distance_in_miles_total: number;
  longest_home_stands_length: number;
  longest_road_trip_length: number;
  back_to_back_match_count: number;
  three_matches_in_five_days_count: number;
  five_matches_in_seven_days_count?: number;
  us_team_border_crossings_border_crossings_count?: number;
  canadian_team_border_crossings_border_crossings_count?: number;
  division_series_first_half?: number;
};

export type FullScheduleMetricsEntry = FullScheduleMetricsEntryRaw & {
  id: string;
  name: string;
};

export type ScheduleNetworkSummary = ScheduleNetworkSummaryEntry[];

export type ScheduleNetworkSummaryEntry = {
  network: string;
  total_viewership: number;
  total_count: number;
  average_viewership: number;
};

export type UserInfoResponse = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};
