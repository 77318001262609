import { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import axios from 'axios';
import { getApiUrl } from 'api/api';

export interface AuthContextType {
  accessToken: string;
  login: (userName: string, password: string) => Promise<Object>;
  logout: () => Promise<void>;
  refresh: () => Promise<AuthData>;
  getUserId: () => string;
  getAccessToken: () => string;
  isLogged: () => boolean;
}

type AuthData = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token: string;
  user_id: string;
};

const AuthContext = createContext<AuthContextType>({
  accessToken: '',
  login: async () => undefined!,
  logout: async () => {},
  refresh: async () => undefined!,
  getUserId: () => '',
  getAccessToken: () => '',
  isLogged: () => false,
});

const LoginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AuthData> => {
  const postReq = await axios.post(`${getApiUrl()}/api/v1/login/`, {
    email,
    password,
  });

  return postReq.data;
};

const RefreshUser = async (refresh_token: string): Promise<AuthData> => {
  const postReq = await axios.post(`${getApiUrl()}/api/v1/refresh/`, {
    refresh_token,
  });

  return postReq.data;
};

const LogoutUser = async (access_token: string) => {
  await axios.post(`${getApiUrl()}/api/v1/logout/`, {
    access_token,
  });
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useLocalStorage<string>({
    key: 'nhl-ubiquia-access-token',
    serialize: (val) => val,
    // For some reason, useLocalStorage does not pick up the value immediately.
    defaultValue: localStorage.getItem('nhl-ubiquia-access-token') || '',
  });
  const [refreshToken, setRefreshToken] = useLocalStorage<string>({
    key: 'nhl-ubiquia-refresh-token',
    serialize: (val) => val,
    defaultValue: localStorage.getItem('nhl-ubiquia-refresh-token') || '',
  });
  const [userId, setUserId] = useLocalStorage<string>({
    key: 'nhl-ubiquia-user-id',
    serialize: (val) => val,
    defaultValue: localStorage.getItem('nhl-ubiquia-user-id') || '',
  });

  const login = async (userName: string, password: string) => {
    const data = await LoginUser({ email: userName, password });
    setRefreshToken(data.refresh_token);
    setUserId(data.user_id);
    setAccessToken(data.access_token);
    return data;
  };

  const logout = async () => {
    if (accessToken) {
      await LogoutUser(accessToken);
    }
    setRefreshToken('');
    setUserId('');
    setAccessToken('');
  };

  const refresh = async () => {
    const data = await RefreshUser(refreshToken);
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setUserId(data.user_id);
    return data;
  };

  const isLogged = () => localStorage.getItem('nhl-ubiquia-access-token') !== '';

  const getAccessToken = () => accessToken;
  const getUserId = () => userId;

  const value = useMemo(
    () => ({
      accessToken,
      login,
      logout,
      refresh,
      getAccessToken,
      getUserId,
      isLogged,
    }),
    [accessToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
