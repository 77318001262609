import { BuildListEntry, SCHEDULE_INDEX_MAP } from 'utils/scheduleConsts';

export const getBuildsListLocal = async (): Promise<BuildListEntry[]> => {
  await new Promise((f) => {
    setTimeout(f, 0);
  });

  return Object.entries(SCHEDULE_INDEX_MAP).map(([id, name]) => ({
    id: `build-${id}`,
    is_valid: true,
    user: 'Me',
    jobs: [
      {
        id: `job-${id}`,
        status: 'solved',
        created_at: '2024-03-27T23:14:33.022625',
      },
    ],
    results: [
      { id, name, created_at: '2024-03-27T23:14:33.022625', is_valid: true, job_id: `job-${id}` },
    ],
    created_at: '2024-03-27T23:14:33.022625',
    name: `Build ${id}`,
  }));
};
