'use client';

import { useForm } from '@mantine/form';

import { PasswordInput, Flex, Container, Button, Paper, Image, rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from 'api/api';

import { createFileRoute } from '@tanstack/react-router';

import ubiquiaLogo from 'assets/ubiquia_logo.svg';

const setPassword = async ({
  token,
  password,
  uid,
}: {
  token: string;
  password: string;
  uid: string;
}): Promise<any> => {
  const postReq = await axios.post(`${getApiUrl()}/api/v1/reset_password/`, {
    token,
    new_password: password,
    uid,
  });

  return postReq.data;
};

export default function ResetPassword() {
  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');

  const handleSubmit = async (values: { password: string }) => {
    try {
      if (token && uid) {
        await setPassword({
          token,
          uid,
          password: values.password,
        });
        window.location.href = '/login';
      } else {
        notifications.show({
          id: 'set-password-error',
          title: 'Oh no! Your password reset link appears invalid.',
          color: 'red',
          message: 'Please try resetting your password again from the login page.',
          position: 'top-center',
        });
      }
    } catch (e: unknown) {
      if (e instanceof AxiosError && e.response) {
        if (e.response.data.new_password) {
          notifications.show({
            id: 'set-password-error',
            title: 'Oh no! There was an error trying to set your password.',
            color: 'red',
            message: (
              <div>
                <p>Please check that your password meets the requirements:</p>
                <br />
                <p className="font-bold">
                  {Object.values(e.response.data.new_password).map((error: any) => (
                    <p>{error}</p>
                  ))}
                </p>
              </div>
            ),
            position: 'top-center',
          });
        } else if (e.response.data.error) {
          notifications.show({
            id: 'set-password-error',
            title: 'Oh no! There was an error trying to set your password.',
            color: 'red',
            message: (
              <div className="font-bold">
                <p>{e.response.data.error}</p>
              </div>
            ),
            position: 'top-center',
          });
        } else {
          notifications.show({
            id: 'set-password-error',
            title: 'Oh no! There was an error trying to set your password.',
            color: 'red',
            message: 'Please try again. Or contact support@recentiveanalytics.com',
            position: 'top-center',
          });
        }
      }
    }
  };

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: {
      password: (value: string) => {
        if (value.length < 8) {
          return 'This password is too short. It must contain at least 8 characters.';
        }
        if (/^\d+$/.test(value)) {
          return 'This password is entirely numeric.';
        }
        return null;
      },
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
    },
  });

  return (
    <Container size={420} my={40} className="h-full">
      <Flex justify="center">
        <Image src={ubiquiaLogo} alt="Ubiquia" h={rem(60)} w="auto" fit="contain" />
      </Flex>
      <Paper withBorder shadow="md" p={30} mt={15} radius="md">
        <form onSubmit={form.onSubmit((values: any) => handleSubmit(values))}>
          <PasswordInput label="New Password" {...form.getInputProps('password')} mt="md" />
          <PasswordInput
            label="Re-enter New Password"
            {...form.getInputProps('confirmPassword')}
            mt="md"
          />
          <Button type="submit" fullWidth mt="xl">
            Set password
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export const Route = createFileRoute('/resetPassword')({
  component: ResetPassword,
});
