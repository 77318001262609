import { Box, Stack, Table, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

import { useAppStore } from 'stores/appStore';

import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { CalendarEvent, ScheduleNetworkSummaryEntry } from 'utils/scheduleConsts';
import { getFullSchedule } from 'utils/scheduleUtils';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const downloadCSV = (entries: CalendarEvent[]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += 'Date,Game,Time (ET),Network,Viewership (000)\n';
  const csvLines = entries.map((e) => {
    const viewership = e.viewership || 0;
    let viewershipStr = `${viewership}`;
    if (viewership >= 1000) {
      viewershipStr = `${(viewership / 1000).toFixed(0)}`;
    }
    const dateStr = dayjs(e.date).format('ddd MMM DD');
    return `${dateStr},${e.title},${e.eastern_time},${e.network ? `"${e.network}"` : ''},${viewershipStr}`;
  });
  const csv = csvContent + csvLines.join('\n');
  const encodedUri = encodeURI(csv);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'Recentive Analytics - NHL Schedule.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function NetworkSummary() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);
  const [networkSummary, setNetworkSummary] = useState<ScheduleNetworkSummaryEntry[]>([]);

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({
        s: prev.s,
        t: 'network',
        team: prev.team,
        compare: prev.compare,
      }),
    });

    async function fetchScheduleNetworkSummary() {
      const schedule = await getFullSchedule(currentScheduleId);

      const networkSummaryMap = new Map<
        string,
        { total_viewership: number; total_count: number; average_viewership: number }
      >();
      for (const entry of schedule) {
        const { network, viewership } = entry;
        if (network && viewership) {
          if (networkSummaryMap.has(network)) {
            const summary = networkSummaryMap.get(network)!;
            summary.total_viewership += viewership;
            summary.total_count += 1;
          } else {
            networkSummaryMap.set(network, {
              total_viewership: viewership,
              total_count: 1,
              average_viewership: 0,
            });
          }
        }
      }

      const currNetworkSummary: ScheduleNetworkSummaryEntry[] = [];
      for (const [network, summary] of networkSummaryMap.entries()) {
        summary.average_viewership = summary.total_viewership / summary.total_count;
        currNetworkSummary.push({ ...summary, network });
      }
      currNetworkSummary.sort((a, b) => b.total_viewership - a.total_viewership);
      setNetworkSummary(currNetworkSummary);
    }
    fetchScheduleNetworkSummary();
  }, [currentScheduleId]);

  return (
    <Stack gap="xs" align="stretch" h="100%">
      {networkSummary.length > 0 ? (
        <Box w="fit-content">
          <Table striped highlightOnHover withTableBorder withColumnBorders withRowBorders={false}>
            <Table.Thead bg="gray.2">
              <Table.Tr>
                <Table.Th>Network</Table.Th>
                <Table.Th>Total Viewership (000)</Table.Th>
                <Table.Th>Total Count</Table.Th>
                <Table.Th>Average Viewership (000)</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {networkSummary.map((entry) => (
                <Table.Tr key={entry.network}>
                  <Table.Td>
                    {entry.network.replace('ESPN_TWO', 'ESPN2').replace('ESPN_PLUS', 'ESPN+')}
                  </Table.Td>
                  <Table.Td>
                    {Number((entry.total_viewership / 1000).toFixed(0)).toLocaleString()}
                  </Table.Td>
                  <Table.Td>{entry.total_count}</Table.Td>
                  <Table.Td>
                    {Number((entry.average_viewership / 1000).toFixed(0)).toLocaleString()}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      ) : (
        <Text>No viewership data available for this schedule.</Text>
      )}
    </Stack>
  );
}
