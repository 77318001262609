import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { AuthContextType } from 'hooks/useAuth';

type RouterContext = {
  authentication: AuthContextType;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Outlet,
});
