// import { useEffect, useMemo, useState } from 'react';
import { Group, Stack, Table, TableData, Title, rem } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/sharp-regular-svg-icons';

import { FullScheduleMetricsEntry } from 'utils/scheduleConsts';

import numeral from 'numeral';
import classes from './CompareTable.module.css';

export function CompareTable({ scheduleMetrics }: { scheduleMetrics: FullScheduleMetricsEntry[] }) {
  const scheduleMetricsSorted = scheduleMetrics.sort(
    (a, b) => b.viewership_total - a.viewership_total
  );
  const head = [
    '',
    ...scheduleMetricsSorted.map((info, idx) => (
      <Group gap="xs" className={idx === 0 ? classes.highestRank : ''}>
        <Title order={6} style={{ textWrap: 'balance' }} title={info.name}>
          {idx === 0 && (
            <FontAwesomeIcon
              style={{ width: rem(14), height: rem(14), paddingRight: '8px' }}
              icon={faBadgeCheck}
            />
          )}
          {info.name}
        </Title>
      </Group>
    )),
  ];
  const rows = [
    [
      'Viewership',
      ...scheduleMetricsSorted.map((info) =>
        info.viewership_total === 0 ? 'n/a' : numeral(info.viewership_total).format('0.0a')
      ),
    ],
    [
      'Average Travel Distance (mi)',
      ...scheduleMetricsSorted.map((info) =>
        numeral(info.travel_distance_in_miles_average).format('0.0a')
      ),
    ],
    ['Longest Home Stand', ...scheduleMetricsSorted.map((info) => info.longest_home_stands_length)],
    ['Longest Road Trip', ...scheduleMetricsSorted.map((info) => info.longest_road_trip_length)],
    ['B2B Games', ...scheduleMetricsSorted.map((info) => info.back_to_back_match_count)],
    [
      '3 Games/5 Days',
      ...scheduleMetricsSorted.map((info) => info.three_matches_in_five_days_count),
    ],
    [
      '5 Games/7 Days',
      ...scheduleMetricsSorted.map((info) => info.five_matches_in_seven_days_count),
    ],
    [
      'First Half Series Count',
      ...scheduleMetricsSorted.map((info) =>
        info.division_series_first_half === null || info.division_series_first_half === undefined
          ? 'n/a'
          : info.division_series_first_half
      ),
    ],
    [
      'US Team Border Crossings',
      ...scheduleMetricsSorted.map((info) =>
        info.us_team_border_crossings_border_crossings_count === null ||
        info.us_team_border_crossings_border_crossings_count === undefined
          ? 'n/a'
          : info.us_team_border_crossings_border_crossings_count
      ),
    ],
    [
      'Canadian Team Border Crossings',
      ...scheduleMetricsSorted.map((info) =>
        info.canadian_team_border_crossings_border_crossings_count === null ||
        info.canadian_team_border_crossings_border_crossings_count === undefined
          ? 'n/a'
          : info.canadian_team_border_crossings_border_crossings_count
      ),
    ],
  ];
  const tableData: TableData = {
    head,
    body: rows,
  };
  return (
    <Stack className={classes.scheduleCompareContainer} gap="md">
      <Table
        horizontalSpacing="sm"
        data={tableData}
        striped
        highlightOnHover
        withRowBorders={false}
        withColumnBorders
      />
    </Stack>
  );
}
