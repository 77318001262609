import { useEffect, useLayoutEffect, useState } from 'react';
import { Box, ScrollArea } from '@mantine/core';
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree';
import { renderers as bpRenderers } from 'react-complex-tree-blueprintjs-renderers';

import { useAppStore } from 'stores/appStore';
import 'react-complex-tree/lib/style-modern.css';
import classes from 'components/ScheduleList/ScheduleList.module.css';
import 'components/ScheduleList/scheduleList.css';
import { processBuildList } from './scheduleListUtils';

export function ScheduleList() {
  const [currentScheduleId, setCurrentScheduleId] = useAppStore((state) => [
    state.currentScheduleId,
    state.setCurrentScheduleId,
  ]);
  const [buildsList] = useAppStore((state) => [state.buildsList]);

  const [focusedItem, setFocusedItem] = useState();
  const buildIds = buildsList.map((build) => build.id);
  const [expandedItems, setExpandedItems] = useState(buildIds);
  const [selectedItems, setSelectedItems] = useState(currentScheduleId ? [currentScheduleId] : []);

  useEffect(() => {
    setSelectedItems([`${currentScheduleId}`]);
  }, [currentScheduleId]);

  useLayoutEffect(() => {
    // Hack to add a title to potentially ellipsed items.
    const treeItems = document.querySelectorAll('.bp5-tree-node-label');
    treeItems.forEach((item) => item.setAttribute('title', item.textContent || ''));
  }, []);

  return (
    <Box className={classes.container} w="100%" h="100%">
      <ScrollArea
        w="100%"
        h="100%"
        className={`${classes.scrollArea} schedule-list-container`}
        classNames={{ viewport: classes.viewport }}
      >
        <ControlledTreeEnvironment
          {...bpRenderers}
          // Filter out builds that have no results.
          items={processBuildList(buildsList.filter((build) => build.results.length > 0)).items}
          getItemTitle={(item) => item.data}
          canDragAndDrop
          canReorderItems
          canDropOnFolder
          canDropOnNonFolder
          viewState={{
            buildTree: {
              focusedItem,
              expandedItems,
              selectedItems,
            },
          }}
          onExpandItem={(item) => {
            // @ts-ignore
            setExpandedItems([...expandedItems, item.index]);
          }}
          onCollapseItem={(item) => {
            // @ts-ignore
            setExpandedItems(
              expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index)
            );
          }}
          onSelectItems={(items) => {
            // @ts-ignore
            if (!items.length) {
              return;
            }
            // @ts-ignore
            if (!buildIds.includes(items[0])) {
              // @ts-ignore
              setCurrentScheduleId(items[0]);
            }
          }}
          // @ts-ignore
          onFocusItem={(item) => setFocusedItem(item.index)}
          // renderItem={(props) => {
          //   const element = createDefaultRenderers(10).renderItem(props);
          //   return element;
          // }}
        >
          <Tree treeId="buildTree" rootItem="root" treeLabel="Tree Example" />
        </ControlledTreeEnvironment>
      </ScrollArea>
    </Box>
  );
}
